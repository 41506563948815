import { Routes, Route, Outlet, Link } from "react-router-dom";
import { adminRoutes, connectedRoutes, publicRoutes } from "./Routes";
import { AdminProtected } from "./AdminProtected";
import { UserProtected } from "./UserProtected";

export default function Navigation() {
  return (
    <>
      <Routes>
        {/* admin */}
        {adminRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={<AdminProtected>{route.component}</AdminProtected>}
          />
        ))}

        {connectedRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={<UserProtected>{route.component}</UserProtected>}
          />
        ))}

        {publicRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={route.component}
          />
        ))}
      </Routes>
    </>
  );
}
