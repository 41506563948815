import React from "react";
import Header from "../components/Header";
import { Footer } from "../components/Footer";
import { ToastContainer } from "react-toastify";
import VerticalLayout from "../components/VerticalLayout";
import { Container, Input, Form } from "reactstrap";
import {} from "react-router-dom";

const Contact = () => {
  //https://codepen.io/rkpasia/pen/LNEQod/
  return (
    <>
      <VerticalLayout />

      <div className="body mb-0">
        <Header />

        <ToastContainer />
        <div className="bg-main-container">
          <div className="main-container scrollable">
            <Container className="width-form">
            
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
