import { constructGetParams, instance } from "./instanceApi"

let path_api = "/music"

export const getList = (params) => {
    let urlParam = "";
    const constructParam = constructGetParams(params);
    if (constructParam) {
        urlParam = `?${constructParam}`;
    }

    return new Promise((resolve, reject) => {
        instance.get(`${path_api}${urlParam}`).then((res) => {
            resolve(res.data);
        })
    })
}

export const getOne = (id) => {
    return new Promise((resolve, reject) => {
        instance.get(`${path_api}/` + id).then((res) => {
            resolve(res.data[0]);
        })
    })
}

export const remove = (id) => {
    return new Promise((resolve, reject) => {
        instance.delete(`${path_api}/` + id).then((res) => {
            resolve(res.data);
        })
    })
}

export const create = (data) => {
    return new Promise((resolve, reject) => {
        instance.post(`${path_api}`, data).then((res) => {
            resolve(res.data);
        })
    })
}

export const downloadTracks = (ids) => {
  return new Promise((resolve, reject) => {
    instance.post(`${path_api}/download-tracks`, ids ).then((res) => {
        resolve(res.data);
    })
})
}