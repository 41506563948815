import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Category } from "../components/Category";
import { CustomScroll } from "react-custom-scroll";
import { Footer } from "../components/Footer";
import * as CategoryService from "../services/category";
import * as MusicService from "../services/music";
import { Col, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";
import ItemTrack from "../components/ItemTrack";
import chevron from "./../assets/images/120890.png";
import { ScaleLoader } from "react-spinners";
import { useProfile } from "../hooks/UserHooks";
import VerticalLayout from "../components/VerticalLayout";
import bg from "../assets/images/home_background.svg";
import { Link, useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa6";

import InfiniteScroll from "react-infinite-scroll-component";

const Home = () => {
  const { defaultVolume, userProfile, updateProfile } = useProfile();

  const navigate = useNavigate();
  const [userProfileDynamique, setUserProfileDynamique] = useState(userProfile);
  const [volumeTrack, setVolumeTrack] = useState(defaultVolume);
  const [selectedCat, setSelectedCat] = useState(null);
  const [hasMore, sethasMore] = useState(true);
  const [listCategory, setListCategory] = useState(null);
  const [listTracks, setListTracks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedTrack, setSelectedTrack] = useState(null);

  /**
   * Ajout ou retirer des favories
   */
  const toggleFav = (track) => {
    if (userProfileDynamique) {
      let user = { ...userProfileDynamique };

      if (!user?.favories) {
        user.favories = [];
      }

      let isExist = user.favories.find((e) => e.id == track.id);

      if (isExist) {
        user.favories = user.favories.filter((e) => e.id != track.id);
      } else {
        user.favories.push(track);
      }
      setUserProfileDynamique(() => user);
      updateProfile(user);
    } else {
      navigate("/login");
    }
  };

  /**
   * Récupération des categories depuis l'api
   */
  const handleCategorie = () => {
    CategoryService.getList().then((res) => {
      setListCategory(res);
    });
  };

  const handleNextTrack = () => {
    MusicService.getList({ limit: 10, offset: listTracks.length }).then((response) => {
      if (response.length > 0) {
        setListTracks(() => [...listTracks, ...response]);
      } else {
        sethasMore(false);
      }
    });
  };

  useEffect(() => {
    handleCategorie();
  }, []);

  useEffect(() => {
    setLoading(true);
    MusicService.getList({ categoryId: selectedCat ? selectedCat.id : null })
      .then((res) => {
        setSelectedTrack(res[0] || null);
        setListTracks(res);
        setLoading(false);
      })
      .catch(() => setLoading(true));
  }, [selectedCat]);

  return (
    <>
      <VerticalLayout />

      <div className="body">
        <div className="container content-body">
          <Header />
          <div className="bg-main-container">
            <Row className="container-bg-gray">
              <Col
                lg={8}
                md={8}
                sm={0}
                className="title-site">
                <h1>Télécharge</h1>
                <h1 className="ilimited-text">sans limite</h1>
                <div className="content-title-btn mx-4 d-flex justify-content-between">
                  {!userProfile && (
                    <>
                      <Link
                        to={"/login"}
                        className="mt-2 text-decoration-none rounded-pill btn btn-color">
                        Connexion
                      </Link>
                      <Link
                        to={"/register"}
                        className="mt-2 text-decoration-none rounded-pill btn btn-outline-color">
                        S'inscrire
                      </Link>
                    </>
                  )}
                </div>
              </Col>
              <Col
                lg={4}
                md={4}
                sm={8}></Col>
            </Row>
          </div>

          <div className="d-flex flex-column align-items-start">
            <div className="d-flex mt-4 align-items-center justify-content-between w-100">
              <div className="d-flex">
                <h4 className="d-flex align-items-center">{selectedCat ? selectedCat.title : "Ajout récent"} :</h4>
              </div>
              <div>
                <Input
                  style={{ border: "none", backgroundColor: "#0d0d0d" }}
                  className="text-light"
                  type="select"
                  onChange={(e) => {
                    console.log(e.target.value);

                    let cat = listCategory.find((cat) => e.target.value == cat.id);
                    setSelectedCat(e.target.value != 0 ? cat : null);
                  }}>
                  <option value={0}>Ajout récent</option>
                  {listCategory?.map((cat) => (
                    <option value={cat.id}>{cat.title}</option>
                  ))}
                </Input>
              </div>
            </div>

            {loading ? (
              <div className="m-5">
                <ScaleLoader color="white" />
              </div>
            ) : (
              <InfiniteScroll
                style={{ width: "100%" }}
                dataLength={listTracks ? listTracks.length : 0}
                next={() => {
                  handleNextTrack();
                }}
                hasMore={hasMore}
                loader={
                  <div className="m-5">
                    <ScaleLoader color="white" />
                  </div>
                }>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th
                        className="list-col-1"
                        style={{}}></th>
                      <th
                        className="list-col-2"
                        style={{}}></th>
                      <th
                        className="list-col-3"
                        style={{ minWidth: "13%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {listTracks.length > 0 ? (
                      listTracks.map((track, i) => (
                        <ItemTrack
                          isFav={userProfileDynamique && userProfileDynamique?.favories?.find((e) => e.id == track.id)}
                          isPlay={selectedTrack?.id == track.id}
                          key={i}
                          toogleFav={() => toggleFav(track)}
                          onClick={() => setSelectedTrack(track)}
                          track={track}
                          volume={volumeTrack ? volumeTrack : 0}
                        />
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <i className="text-secondary">Cette catégorie est vide pour le moment</i>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
          </div>
          <Footer selectedTrack={selectedTrack} />
        </div>
      </div>
    </>
  );
};

export default Home;
