import React, { useState } from "react";
import { useProfile } from "../hooks/UserHooks";
import { Navigate } from "react-router-dom";

export const UserProtected = ({ children }) => {
  const { userProfile, loading } = useProfile();

  if (!userProfile && !loading) {
    return <Navigate to={{ pathname: "/not-found-404" }} />;
  }

  return <>{children}</>;
};
