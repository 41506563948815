import { BehaviorSubject } from "rxjs";

let cookieTrack = localStorage.getItem("trackList");
console.log(cookieTrack);

const trackList = cookieTrack ? JSON.parse(cookieTrack) : [];

export const trackList$ = new BehaviorSubject(trackList);

export const deleteTrack = (id) => {
  let index = trackList.findIndex((e) => e.id == id);
  trackList.splice(index, 1);

  localStorage.setItem("trackList", JSON.stringify(trackList));
  trackList$.next(trackList);
};

export const addTrack = (track) => {
  trackList.push(track);

  localStorage.setItem("trackList", JSON.stringify(trackList));
  trackList$.next(trackList);
};
