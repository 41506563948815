import React, { useEffect, useState } from "react";
import VerticalLayout from "../components/VerticalLayout";
import Header from "../components/Header";
import { Col, Container, Input, Label, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { ScaleLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemTrack from "../components/ItemTrack";
import { useProfile } from "../hooks/UserHooks";
import * as UserService from "../services/user";

const Profile = () => {
  const { userProfile, updateProfile, defaultVolume } = useProfile();
  const navigate = useNavigate();

  const [hasMore, setHasMore] = useState(true);
  const [userProfileDynamique, setUserProfileDynamique] = useState(userProfile);
  const [volumeTrack, setVolumeTrack] = useState(defaultVolume);
  const [listFav, setListFav] = useState(userProfile.favories.slice(0, 5));
  const [selectedTrack, setSelectedTrack] = useState(null);

  /**
   * Ajout ou retirer des favories
   */
  const toggleFav = (track) => {
    if (userProfileDynamique) {
      let user = { ...userProfileDynamique };
      let isExist = user.favories.find((e) => e.id == track.id);
      if (isExist) {
        user.favories = user.favories.filter((e) => e.id != track.id);
      } else {
        user.favories.push(track);
      }
      setUserProfileDynamique(user);
      updateProfile(user);
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (userProfile.favories.length > 5) {
      setHasMore(false);
    }
  }, []);

  return (
    <>
      <VerticalLayout />

      <div className="body">
        <div className="container content-body">
          <Header />
          <Container>
            <Row>
              <Col lg={12}>
                <div className="card m-2 p-4">
                  <div>
                    <h3>Profile</h3>
                    <Col className="d-flex flex-column align-items-start">
                      <Label className="text-dark">Nom</Label>
                      <Input value={userProfileDynamique.lastname}></Input>
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <Label className="text-dark">Prénom</Label>
                      <Input value={userProfileDynamique.firstname}></Input>
                    </Col>
                    <Col className="d-flex flex-column align-items-start">
                      <Label className="text-dark">Email</Label>
                      <Input value={userProfileDynamique.email}></Input>
                    </Col>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="m-2">
                  <h3>favories</h3>

                  <InfiniteScroll
                    hasMore={hasMore}
                    style={{ width: "100%" }}
                    dataLength={listFav ? listFav.length : 0}
                    next={() => {
                      let nextData = userProfile.favories.slice(listFav.length, 5);

                      if (nextData.length) {
                        setListFav(() => [...listFav, ...nextData]);
                      } else {
                        setHasMore(false);
                      }
                    }}
                    loader={
                      <div className="m-5">
                        <ScaleLoader color="white" />
                      </div>
                    }>
                    <table className="w-100">
                      <thead>
                        <tr>
                          <th style={{ width: "33%" }}></th>
                          <th style={{ width: "33%" }}></th>
                          <th style={{ minWidth: "33%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {listFav &&
                          listFav.map((track, i) => (
                            <ItemTrack
                              isFav={userProfileDynamique && userProfileDynamique?.favories?.find((e) => e.id == track.id)}
                              isPlay={selectedTrack?.id == track.id}
                              key={i}
                              toogleFav={() => toggleFav(track)}
                              onClick={() => setSelectedTrack(track)}
                              track={track}
                              volume={volumeTrack ? volumeTrack : 0}
                            />
                          ))}
                      </tbody>
                    </table>
                  </InfiniteScroll>
                </div>
              </Col>
            </Row>
          </Container>
          <Footer selectedTrack={selectedTrack} />
        </div>
      </div>
    </>
  );
};

export default Profile;
